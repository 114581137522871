<template>
  <fragment>
    <h1>Realizá tu consulta</h1>
    <p>
      Completá el siguiente formulario para enviarnos tu consulta. No olvides
      indicar tu correo electrónico para que podamos comunicarnos con vos a la
      brevedad.
    </p>

    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <v-form @submit.prevent="handleSubmit(submit)">
        <ValidationProvider v-slot="{ errors }" rules="required" vid="name">
          <v-text-field
            v-model="name"
            label="Nombre y Apellido / Razón social"
            required
            outlined
            :error-messages="errors"
          />
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors }"
          rules="required|email"
          vid="email"
        >
          <v-text-field
            v-model="email"
            label="Correo electrónico"
            required
            outlined
            :error-messages="errors"
          />
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" rules="required" vid="issue">
          <v-text-field
            v-model="issue"
            label="Asunto"
            required
            outlined
            :error-messages="errors"
          />
        </ValidationProvider>

        <ValidationProvider v-slot="{ errors }" rules="required" vid="message">
          <v-textarea
            v-model="message"
            label="Consulta"
            outlined
            :error-messages="errors"
          />
        </ValidationProvider>

        <v-btn color="primary" type="submit" :disabled="disabled" tile>
          ENVIAR
        </v-btn>

        <VueRecaptcha
          ref="recaptcha"
          :sitekey="$config.recaptcha.siteKey"
          size="invisible"
          @verify="onCaptchaVerified"
          @expired="onCaptchaExpired"
        />
      </v-form>
    </ValidationObserver>
  </fragment>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";

export default {
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      name: null,
      email: null,
      issue: null,
      message: null,
      disabled: false,
    };
  },
  mounted() {
    const recaptchaScript = document.createElement("script");

    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    );
    recaptchaScript.async = true;
    recaptchaScript.defer = true;

    document.head.appendChild(recaptchaScript);
  },

  methods: {
    async submit() {
      this.$refs.recaptcha.execute();
    },

    async onCaptchaVerified(recaptchaToken) {
      if (this.$refs.form.validate()) {
        this.$refs.recaptcha.reset();
        this.disabled = true;

        let data = {
          contact: {
            name: this.name,
            issue: this.issue,
            email: this.email,
            message: this.message,
          },
          token: recaptchaToken,
        };

        this.$axios
          .post("/api/v1/contact", data)
          .then((response) => {
            this.$toasted.success(response.data.message);
            this.$router.push({ name: "mail-sent" });
          })
          .catch((error) => {
            console.log("Error:", error);
            this.disabled = false;
            this.$toasted.error(
              "Ha ocurrido un error, por favor inténtelo nuevamente más tarde."
            );
          });
      }
    },

    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
  },
};
</script>

<style lang="sass">
@import "@/assets/styles/common";
</style>
