<template>
  <v-app-bar app elevation="0" color="primary" height="90">
    <router-link to="/">
      <v-img
        contain
        :src="IsologotipoBBlanco"
        title="Gobierno de la Provincia de Buenos Aires"
        alt="Gobierno de la Provincia de Buenos Aires"
        height="90"
        width="200"
      ></v-img>
    </router-link>
  </v-app-bar>
</template>

<script>
import IsologotipoBBlanco from "@/assets/images/isologotipo_b_blanco.svg";

export default {
  name: "Navbar",
  data: () => {
    return {
      IsologotipoBBlanco,
      drawer: false,
    };
  },
};
</script>
