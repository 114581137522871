// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
// require("turbolinks").start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Vue from "vue";
import toasted from "@/plugins/toasted";
import routes from "@/plugins/routes";
import fragment from "@/plugins/fragment";
import vuetify from "@/plugins/vuetify";
import axios from "@/plugins/axios";
import "@/plugins/vee-validate";

import App from "@/components/App";

import "@/assets/images/favicon.ico";

// Use packages
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

document.addEventListener("DOMContentLoaded", () => {
  Vue.prototype.$config = JSON.parse(
    document.getElementById("app").dataset.config
  );

  const app = new Vue({
    vuetify,
    toasted,
    fragment,
    router: routes,
    render: (h) => h(App),
  }).$mount("#app");

  document.body.appendChild(app.$el);
});
