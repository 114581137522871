import Vue from 'vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, confirmed, length, email } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

extend("required", {
  ...required,
  message: 'No puede estar en blanco.'
});

// extend('dossierNumber', value => {
//   const pattern = /EX-(?<year>\d{4})-(?<number>\d{0,8})-(( )?-)?GDEBA-(?<organization>\w+)/
//   return pattern.test(value) || 'No corresponde a un número de expediente.'
// });

extend("email", {
  ...email,
  message: "Debe ser un email válido"
});

// extend("confirmed", {
//   ...confirmed,
//   message: "This field confirmation does not match"
// });

// extend("length", {
//   ...length,
//   message: "This field must have 2 options"
// });
