import Vue from "vue";
import Vuetify from "vuetify";

import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

// https://vuetifyjs.com/en/features/icons/#reusable-custom-icons
// https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/services/icons/presets/mdi-svg.ts

import {
  mdiAccount,
  mdiAccountCircle,
  mdiAccountGroup,
  mdiArrowLeft,
  mdiArrowDownBold,
  mdiAttachment,
  mdiBook,
  mdiCheckOutline,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCogOutline,
  mdiDelete,
  mdiDeleteForever,
  mdiDeleteForeverOutline,
  mdiDeleteRestore,
  mdiDeleteOutline,
  mdiDomain,
  mdiDotsVertical,
  mdiDownload,
  mdiDownloadOutline,
  mdiExitToApp,
  mdiFileFind,
  mdiFilePdf,
  mdiFilePdfBox,
  mdiFilterMenu,
  mdiHelpCircleOutline,
  mdiKeyboardBackspace,
  mdiMagnify,
  mdiOpenInNew,
  mdiPencil,
  mdiSwapHorizontal,
} from "@mdi/js";

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg", // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: "#00aec3",
        secondary: "#414042",
      },
    },
  },
});
