import Contact from "../components/contact/Contact";
import MailSent from "../components/contact/MailSent";

const baseRoutes = [
  { path: "/", component: Contact, name: "contact" },
  { path: "/contact", component: Contact, name: "contact" },
  { path: "/consulta-enviada", component: MailSent, name: "mail-sent" },
];

export default baseRoutes;
