<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>Tu consulta fue enviada.</h1>
        <p>
          Muchas gracias por comunicarte con la
          <a
            href="https://www.gba.gob.ar/secretariageneral"
            target="_blank"
            rel="nofollow noreferrer"
            title="Secretaría General"
            >Secretaría General de la Gobernación</a
          >
          de la Provincia de Buenos Aires.
        </p>
        <p>
          Nos comunicaremos con vos a la brevedad con la respuesta a tu
          consulta.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <MoreInfo />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MoreInfo from "@/components/contact/MoreInfo";

export default {
  name: "MailSent",
  components: {
    MoreInfo,
  },
};
</script>

<style lang="sass">
@import "@/assets/styles/common";
</style>
