var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('h1',[_vm._v("Realizá tu consulta")]),_vm._v(" "),_c('p',[_vm._v("\n    Completá el siguiente formulario para enviarnos tu consulta. No olvides\n    indicar tu correo electrónico para que podamos comunicarnos con vos a la\n    brevedad.\n  ")]),_vm._v(" "),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('ValidationProvider',{attrs:{"rules":"required","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre y Apellido / Razón social","required":"","outlined":"","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Correo electrónico","required":"","outlined":"","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","vid":"issue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Asunto","required":"","outlined":"","error-messages":errors},model:{value:(_vm.issue),callback:function ($$v) {_vm.issue=$$v},expression:"issue"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required","vid":"message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Consulta","outlined":"","error-messages":errors},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,true)}),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":_vm.disabled,"tile":""}},[_vm._v("\n        ENVIAR\n      ")]),_vm._v(" "),_c('VueRecaptcha',{ref:"recaptcha",attrs:{"sitekey":_vm.$config.recaptcha.siteKey,"size":"invisible"},on:{"verify":_vm.onCaptchaVerified,"expired":_vm.onCaptchaExpired}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }