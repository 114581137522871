<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <AnotherContactForm />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <MoreInfo />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import AnotherContactForm from "@/components/contact/AnotherContactForm";
import MoreInfo from "@/components/contact/MoreInfo";

export default {
  name: "Contact",
  components: {
    AnotherContactForm,
    MoreInfo,
  },
};
</script>
